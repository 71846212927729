<template>
    <div class="segment-button">
        <div
            v-for="option in options"
            :key="option.value"
            class="segment-button__option"
            :class="{
                'segment-button__option--selected': valueInternal.includes(
                    option.value
                ),
            }"
            @click="handleOptionClick(option)"
        >
            {{ option.label }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'SegmentButton',
    props: {
        multiple: {
            type: Boolean,
            default: false,
        },
        options: {
            type: Array,
            required: true,
        },
        value: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            valueInternal: [],
        }
    },
    watch: {
        value() {
            this.valueInternal = this.value
        },
    },
    mounted() {
        this.valueInternal = this.value
    },
    methods: {
        handleOptionClick(option) {
            if (this.valueInternal.includes(option.value)) {
                return
            }

            this.valueInternal = this.multiple
                ? [...this.valueInternal, option.value]
                : [option.value]

            this.$emit('input', this.valueInternal)
        },
    },
}
</script>

<style lang="scss" scoped>
.segment-button {
    display: flex;
    border-radius: 8px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08);
    overflow: hidden;

    &__option {
        flex: 1;
        height: 100%;
        padding: 10px 16px;
        background-color: $color-primary-contrast;
        line-height: 20px;
        text-align: center;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: $color-primary-text;
        cursor: pointer;
        transition: all 0.1s;

        &:hover {
            background-color: mix(#fff, $color-gray-lighter-new, 55%);
        }

        & + & {
            border-left: 1px solid rgba(0, 0, 0, 0.1);
        }

        &--selected {
            background-color: $color-primary !important;
            border-color: $color-primary-contrast !important;
            color: $color-primary-contrast;
            cursor: default;

            & + div {
                border-color: $color-primary-contrast !important;
            }
        }
    }
}
</style>
