<template>
    <div
        class="base-card"
        :class="{
            'base-card--padded': padded,
        }"
    >
        <slot />
    </div>
</template>

<script>
export default {
    name: 'BaseCard',
    props: {
        padded: {
            type: Boolean,
        },
    },
}
</script>

<style lang="scss" scoped>
.base-card {
    padding: 8px;
    background-color: #fff;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
    border: 1px solid #fff;
    border-radius: 8px;
    font-size: 14px;
    color: $color-text-new;

    &--padded {
        padding: 24px 16px;
    }
}
</style>
