<template>
    <div class="l-padded">
        <div class="l-stack l-gap-2 dashboard-rfid-view">
            <h3 class="t-title">
                {{ $t('title') }}
            </h3>

            <div class="l-stack l-gap-2">
                <BaseInput
                    ref="rfidInput"
                    v-model="value"
                    :placeholder="$t('rfid')"
                    block
                    clearable
                />

                <BaseCard padded>
                    <div v-if="tracker" class="l-stack l-gap-2">
                        <div class="l-inline l-gap-2 l-center-v">
                            <AssetAvatar
                                :tracker="tracker"
                                :size="40"
                                linkable
                            />

                            <div class="l-stack l-gap-0">
                                <h3>
                                    {{ tracker.asset_details.name }}
                                </h3>

                                <span class="t-small">
                                    {{ tracker.asset_details.identification }}
                                </span>
                            </div>
                        </div>

                        <div>
                            <p>
                                {{ $t('homeLocation') }}:
                                {{
                                    tracker.location_details
                                        ? tracker.location_details.name
                                        : '–'
                                }}
                            </p>

                            <p>
                                {{ $t('currentLocation') }}:
                                {{ trackerCurrentLocations || '–' }}
                            </p>
                        </div>
                    </div>

                    <div v-else class="t-subtle">
                        {{ $t('rfidNote') }}
                    </div>
                </BaseCard>

                <SegmentButton
                    v-model="positionType"
                    :options="positionTypeOptions"
                />

                <BaseMultiselect
                    v-if="positionType[0] === 'geofence'"
                    v-model="location"
                    :placeholder="$t('location')"
                    :allow-empty="false"
                    :options="locationsSortedByName"
                    track-by="id"
                    label="name"
                    block
                />

                <BaseCard v-else-if="positionType[0] === 'geolocation'" padded>
                    <VSpinner
                        v-if="isGeolocationLoading"
                        size="medium"
                        line-fg-color="black"
                        :speed="1"
                    />

                    <p v-else-if="isGeolocationFailed">
                        {{ $t('geolocationFailed') }}
                    </p>

                    <p v-else-if="geolocation">
                        {{ $t('latitude') }}: {{ geolocation.latitude }} <br />
                        {{ $t('longitude') }}: {{ geolocation.longitude }}
                    </p>
                </BaseCard>

                <BaseCard
                    v-show="positionType[0] === 'map'"
                    class="dashboard-rfid-view__map"
                >
                    <LMap
                        ref="map"
                        :options="{
                            attributionControl: false,
                            preferCanvas: true,
                            zoomControl: false,
                        }"
                        :max-bounds="[
                            [-90, -270],
                            [90, 270],
                        ]"
                        :max-bounds-viscosity="0.75"
                        :max-zoom="activeLayer.maxZoom || 20"
                        :min-zoom="activeLayer.minZoom || 2"
                        @click="handleMapClick"
                    />
                </BaseCard>

                <BaseButton
                    :is-loading="isSubmitting"
                    :disabled="isDisabled"
                    type="submit"
                    @click="handleSubmit"
                >
                    {{ $t('shared.save') }}
                </BaseButton>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import { latLngBounds, popup } from 'leaflet'
import { LMap } from 'vue2-leaflet'
import VSpinner from 'vue-simple-spinner'

import { httpHelper } from '@/utils'
import AssetAvatar from '@/components/AssetAvatar'
import BaseButton from '@/components/redesigned/BaseButton'
import BaseCard from '@/components/redesigned/BaseCard'
import BaseInput from '@/components/redesigned/BaseInput'
import BaseMultiselect from '@/components/redesigned/BaseMultiselect'
import SegmentButton from '@/components/redesigned/SegmentButton'

export default {
    name: 'DashboardRfidView',
    components: {
        AssetAvatar,
        BaseButton,
        BaseCard,
        BaseInput,
        BaseMultiselect,
        LMap,
        SegmentButton,
        VSpinner,
    },
    data() {
        return {
            geolocation: null,
            isGeolocationFailed: false,
            isGeolocationLoading: false,
            isSubmitting: false,
            location: null,
            mapPosition: null,
            positionType: ['geofence'],
            positionTypeOptions: [
                { value: 'geofence', label: this.$t('geofence') },
                { value: 'geolocation', label: this.$t('geolocation') },
                { value: 'map', label: this.$t('map') },
            ],
            value: '',
        }
    },
    computed: {
        ...mapState('map', ['tileProviders']),
        ...mapState('tracker', ['trackers']),
        ...mapGetters('location', ['locationsSortedByName']),
        ...mapGetters('map', ['activeLayer', 'defaultLayer']),
        isDisabled() {
            if (!this.tracker) {
                return true
            }

            switch (this.positionType[0]) {
                case 'geofence':
                    return !this.location
                case 'geolocation':
                    return !this.geolocation
                case 'map':
                    return !this.mapPosition
            }

            return false
        },
        tracker() {
            const query = this.value.trim().toLowerCase()
            return this.trackers.find(
                tracker => tracker.deveui.toLowerCase() === query
            )
        },
        trackerCurrentLocations() {
            return this.tracker?.asset_details.current_locations
                .map(
                    id =>
                        this.locationsSortedByName.find(item => item.id === id)
                            ?.name
                )
                .filter(Boolean)
                .join(', ')
        },
    },
    watch: {
        positionType() {
            if (
                this.positionType[0] === 'geolocation' &&
                !this.geolocation &&
                !this.isGeolocationLoading
            ) {
                if (!navigator.geolocation) {
                    this.isGeolocationFailed = true
                    return
                }
                this.isGeolocationLoading = true
                navigator.geolocation.getCurrentPosition(
                    ({ coords: { latitude, longitude } }) => {
                        this.isGeolocationLoading = false
                        this.geolocation = { latitude, longitude }
                    },
                    () => {
                        this.isGeolocationLoading = false
                        this.isGeolocationFailed = true
                    }
                )
            } else if (this.positionType[0] === 'map') {
                this.$nextTick(() => {
                    this.$refs.map.mapObject.invalidateSize()
                })
            }
        },
    },
    mounted() {
        this.$refs.rfidInput.focus()

        if (this.activeLayer.layer) {
            this.$refs.map.mapObject.addLayer(this.activeLayer.layer)
        } else {
            this.$refs.map.mapObject.addLayer(this.defaultLayer.layer)
            this.setActiveLayerId(this.defaultLayer.id)
        }
    },
    methods: {
        ...mapMutations('map', ['setActiveLayerId']),
        handleMapClick({ latlng }) {
            this.mapPosition = latlng
            const positionPopup = popup({ closeButton: false })
                .setLatLng(latlng)
                .setContent(
                    `${this.$t('latitude')}: ${latlng.lat}<br>${this.$t(
                        'longitude'
                    )}: ${latlng.lng}`
                )
            this.$refs.map.mapObject.openPopup(positionPopup)
        },
        async handleSubmit() {
            this.isSubmitting = true

            let center
            let latitude
            let longitude

            switch (this.positionType[0]) {
                case 'geofence':
                    center = latLngBounds(
                        this.location.geofence.coordinates[0].map(
                            ([lng, lat]) => [lat, lng]
                        )
                    ).getCenter()
                    latitude = center.lat
                    longitude = center.lng
                    break
                case 'geolocation':
                    latitude = this.geolocation.latitude
                    longitude = this.geolocation.longitude
                    break
                case 'map':
                    latitude = this.mapPosition.lat
                    longitude = this.mapPosition.lng
                    break
            }

            await httpHelper.post('http-receive/', {
                identifier: this.value,
                position: {
                    latitude,
                    longitude,
                },
            })

            this.value = ''
            this.isSubmitting = false
            this.$refs.rfidInput.focus()
        },
    },
}
</script>

<i18n>
{
    "en": {
        "currentLocation": "Current location",
        "geofence": "Geofence",
        "geolocation": "Geolocation",
        "geolocationFailed": "Failed to get the device location",
        "homeLocation": "Home location",
        "latitude": "Latitude",
        "location": "Location",
        "longitude": "Longitude",
        "map": "Map",
        "rfid": "RFID",
        "rfidNote": "Please scan RFID",
        "title": "Scan RFID"
    },
    "de": {
        "currentLocation": "Aktueller Standort",
        "geofence": "Geofence",
        "geolocation": "Geolokalisierung",
        "geolocationFailed": "Abfrage des Gerätestandorts fehlgeschlagen",
        "homeLocation": "Home Standort",
        "latitude": "Latitude",
        "location": "Standort",
        "longitude": "Longitude",
        "map": "Karte",
        "rfid": "RFID",
        "rfidNote": "Bitte scannen Sie die RFID",
        "title": "RFID scannen"
    },
    "fr": {
        "currentLocation": "Emplacement actuel",
        "geofence": "Geofence",
        "geolocation": "Géolocalisation",
        "geolocationFailed": "Échec de l'obtention de l'emplacement de l'appareil",
        "homeLocation": "Home location",
        "latitude": "Latitude",
        "location": "Emplacement",
        "longitude": "Longitude",
        "map": "Carte",
        "rfid": "RFID",
        "rfidNote": "Veuillez scanner RFID",
        "title": "Scan RFID"
    },
    "it": {
        "currentLocation": "Locazione attuale",
        "geofence": "Geofence",
        "geolocation": "Geolocalizzazione",
        "geolocationFailed": "Impossibile ottenere la posizione del dispositivo",
        "homeLocation": "Home location",
        "latitude": "Latitude",
        "location": "Locazione",
        "longitude": "Longitude",
        "map": "Carta",
        "rfid": "RFID",
        "rfidNote": "Si prega di eseguire la scansione RFID",
        "title": "Scansione RFID"
    }
}
</i18n>

<style lang="scss" scoped>
.dashboard-rfid-view {
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    max-width: 600px;

    &__map {
        width: 100%;
        height: 280px;
        padding: 0;
        overflow: hidden;
    }
}
</style>
